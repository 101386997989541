import firebase from 'firebase/compat/app';
import { useEffect, useState } from "react";
import { getFirestore, doc, onSnapshot } from "firebase/firestore";

const a = 1986;
const b = 1982;
const c = 1;

const firebaseConfig = {
  apiKey: "AIzaSyCV0mnGt" + (a - b) + d() + "CQOoFgmmoyE_" + d() + "yHqbRDMEsqQ",
  projectId: "followflight-" + e(),
  appId: "1:364337264488:web:64c47c48eb3a476e1fc8dd"
};

function d() {
  return "y" + c;
}

function e() {
  return "f0c00";
}

export const app = firebase.initializeApp(firebaseConfig);
const db = getFirestore(app);


function App() {
  const [number, setNumber] = useState(765555);
  const [flashing, setFlashing] = useState(true);

  useEffect(() => {
    return onSnapshot(doc(db, "counter", "trace"), (doc) => {
      setNumber(doc.data().count);
      setFlashing(true);
      setTimeout(() => {
        setFlashing(false);
      }, 1000);
    });
  }, []);

  const flashingClass = flashing ? "acetrace-counter-number-flashing" : "";
  const className = flashingClass +  " acetrace-counter-number";

  return (
    <div className="acetrace-counter">
      <div className={className}>{number}</div>
      <div className="acetrace-counter-text">shots traced</div>
    </div>
  );
}

export default App;
